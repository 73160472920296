import { useQuery } from "@tanstack/react-query";
import { useUserProfileClient } from "../userProfileApiClient";

export const useFeaturesGetQuery = () => {
  const { createClient } = useUserProfileClient();

  return useQuery({
    queryKey: ["useFeaturesGet"],
    queryFn: async () => (await createClient()).featureFlags.featuresGet(),
    throwOnError: false,
  });
};
