import { useQuery } from "@tanstack/react-query";
import { useUserProfileClient } from "../userProfileApiClient";

export const useGeneralSettingsGetQuery = () => {
  const { createClient } = useUserProfileClient();

  return useQuery({
    queryKey: ["useGeneralSettingsGet"],
    queryFn: async () =>
      (await createClient()).generalSettings.generalSettingsGet(),
    throwOnError: false,
  });
};
